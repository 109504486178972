$secondaryColor: #f44336;
$buttonShadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
$textColor: rgba(0, 0, 0, 0.87);
$sidebarBg: #232e3e;
$sidebarTextColor: #e9ecef;
$text-primary-color: #212631;
$content-min-height: calc(100vh - 62px);

/*
	Shades of green:
	#aad15f
	#90cf44 
	#0b6623
	#59b512
	#54AC5A
	#BCD76D
*/
$primaryGreen: #54ac5a;
