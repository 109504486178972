.header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px 16px;
	height: 62px;

	.logo {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	.navigation {
		display: flex;
		gap: 20px;
		align-items: center;
	}

	.navigation > .nav-item > a {
		text-decoration: none;
		color: #000;
	}

	// .nav-item.login .login-link
	.nav-item.signup .signup-link {
		border-radius: 4px;
		cursor: pointer;
		box-shadow: $buttonShadow;
		color: #fff;
		padding: 10px 30px;
		text-shadow: 0px 0px 0px #fff;
	}

	.nav-item.signup .signup-link {
		@include primary-green-btn;
	}

	.nav-item.login .login-link {
		@include gray-btn;
	}
}
