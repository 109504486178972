@import "~rsuite/dist/rsuite-no-reset.min.css";
// @import "~bootstrap/scss/bootstrap";

// reset styles
@import "base/_base-dir";

// Abstracts
@import "abstracts/_abstracts-dir";

// Components
@import "components/_components-dir";

// Layout
@import "layouts/_layouts-dir";

// Pages
@import "pages/_pages-dir";

// Business manager platform
@import "businessManager/components/_components-dir";
@import "businessManager/views/_views-dir";
