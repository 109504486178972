#home {
	max-height: calc(100% - 62px);
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.search_section {
		height: calc(60vh - 62px);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		// overflow: hidden;
		gap: 20px;
	}

	.showcase_section {
		display: flex;
		width: 150vw;
		position: relative;
		right: 50px;
	}

	.pics_navigation {
		display: flex;
		gap: 20px;
		flex-wrap: wrap;
		max-height: 40vh;

		img {
			max-height: 180px;
			border-radius: 15px;
			background: center / contain no-repeat;
		}
	}
}
