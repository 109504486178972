.user-header {
	position: relative;
	display: flex;
	align-items: center;
	// justify-content: space-b;
	padding: 10px 16px;
	height: 62px;
	// width: 100%;

	// .logo {
	// 	position: absolute;
	// 	left: 50%;
	// 	transform: translateX(-50%);
	// }

	.navigation {
		display: flex;
		gap: 30px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.navigation > .nav-item > a {
		text-decoration: none;
		color: #000;
	}

	.nav-item.login .login-link,
	.nav-item.signup .signup-link {
		border-radius: 4px;
		cursor: pointer;
		box-shadow: $buttonShadow;
		color: #fff;
		padding: 6px 16px;
		text-shadow: 0px 0px 0px #fff;
	}
}

.user-dd-item {
	width: 100%;
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0;
	margin: 0;
	gap: 8px;
	padding: 8px 12px;
}
