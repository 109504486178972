@mixin primary-green-btn {
	background-color: $primaryGreen;
	transition: ease 300ms;

	&:hover {
		opacity: 0.8;
	}
}

@mixin gray-btn {
	background-color: #efefef;
	border-radius: 4px;
	cursor: pointer;
	padding: 10px 30px;
	box-shadow: $buttonShadow;
	text-shadow: 0px 0px 0px #fff;
	transition: ease 300ms;

	&:hover {
		opacity: 0.8;
	}
}
