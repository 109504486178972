* {
	padding: 0;
	margin: 0;
	list-style: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

// html,
body {
	min-height: 100vh;
	font-family: "Kanit", sans-serif;
}

img,
picture,
svg,
video {
	display: block;
	max-width: 100%;
}

input,
textarea,
button,
select {
	font: inherit;
}

a {
	text-decoration: none;
	color: var(--rs-text-primary);
}
