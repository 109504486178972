#home {
	.searchbar_container {
		display: flex;
		width: 45%;
		// justify-content: center;
		gap: 9px;
		position: relative;
	}

	.searchbar {
		padding: 6px 12px;
		display: flex;
		align-items: center;
		border: 1px solid lightgray;
		border-radius: 2px;
		width: 80%;
		gap: 10px;

		.location-icon {
			position: relative;
			bottom: 2px;
		}

		input {
			border: none;
			width: 100%;
			outline: none;
		}
	}

	.search-btn {
		border: none;
		border-radius: 4px;
		width: 100px;
		cursor: pointer;
		background-color: $primaryGreen;
		color: #fff;
		box-shadow: $buttonShadow;
	}

	.search_results {
		position: absolute;
		left: 0;
		top: 120%;
		width: 98%;
		border-radius: 0 0 10px 10px;
		background-color: #fff;
		z-index: 2;
		box-shadow: $buttonShadow;

		.nav-list {
			display: flex;
			flex-direction: column;
		}

		.found-item {
			font-size: 14px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			padding: 8px 14px;
			cursor: default;

			&:hover {
				background-color: #f4f4f4;
			}
		}

		.business-name {
			font-size: 15px;
			font-weight: 500;
			// margin-left: 8px;
			color: #212121;
		}

		.business-address {
			font-size: 14px;
			display: flex;
			gap: 5px;
			color: #70757a;
		}

		.location-icon {
			position: relative;
			top: 1px;
		}
	}
}
