.external-auth-btns-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin: 10px 0;

	.g-auth-btn {
		display: flex;
		justify-content: center;
		padding: 8px 16px;
		align-items: center;
		gap: 10px;
		background: white;
		color: rgba(0, 0, 0, 0.87);
		border-radius: 4px;
		border: 0.5px solid rgba(0, 0, 0, 0.23);
		box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.23);
		font-size: 16px;
		cursor: pointer;
	}

	.fb-auth-btn {
		display: flex;
		justify-content: center;
		padding: 8px 16px;
		align-items: center;
		gap: 10px;
		background: #1877f2;
		color: #fff;
		border-radius: 4px;
		border: none;
		font-weight: 500;
		box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.23);
		font-size: 16px;
		cursor: pointer;
	}
}
