.auth_header {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);

  // background-color: #fb8500;
  // border-bottom: 1px solid rgb(235, 235, 235);
  // #ffb703, #fb8500

  .logo {
    color: #000;
    text-decoration: none;
    position: relative;
    left: 30px;
    // font-weight: 400;
    // color: #fff;
  }
}
